<script>
import { Pie, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;
export default {
  extends: Pie,
  mixins: [reactiveProp],
  data() {
    return {
      options: {
        //Chart.js options        
        tooltips: {
          backgroundColor: '#d84c52',
          displayColors: false,
          bodyFontSize: 18,
          xPadding: 16,
          yPadding: 16,
          callbacks: {
                label: function(tooltipItem, data) {                    
                    let sum = 0;
                    data.datasets[0].data.forEach(element => {
                      sum += element;
                    });                    
                    return data.labels[tooltipItem.index] + ' : ' + (data.datasets[0].data[tooltipItem.index]/sum*100).toFixed(2) + '%' + ' (' + data.datasets[0].data[tooltipItem.index].toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') + ')';
                },
                title() {
                  return ''
                },                
                labelTextColor: function(tooltipItem, chart) {
                    return '#fff';
                }
            }
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>